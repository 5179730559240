import { useEffect, useState } from "react";
import { data } from "../data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../styles/BlogPage.css";
import BlogSection from "./BlogSection";
import { blogList } from "../data";
import { Helmet } from "react-helmet";
const BlogPage = () => {
  const location = useLocation();
  const { id } = location?.state || "";
  const filteredBlog = data.filter((blog) => blog?.blogId === id)[0];
  const blog = filteredBlog?.blogCard__data;
  const [blogArr, setBlogArr] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setBlogArr(blogList);
  }, []);

  const formatTitleForURL = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };
  const handleNavigation = (id, metaTitle) => {
    const formattedText = formatTitleForURL(metaTitle);
    navigate(`/blogs/${formattedText}`,{state:{id}});
  };

  const handleFacebookShare = () => {
    const url = encodeURIComponent(window.location.href);
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;

    const width = 600;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      facebookShareUrl,
      "facebook-share-dialog",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  const handleWhatsAppShare = () => {
    const message = encodeURIComponent(
      "Check out this blog: " + window.location.href
    );
    const whatsappShareUrl = `https://wa.me/?text=${message}`;

    const width = 600;
    const height = 400;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      whatsappShareUrl,
      "whatsapp-share-dialog",
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offset = 160; // Adjust this based on your header height
      const sectionPosition = sectionElement.offsetTop - offset;
  
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>{filteredBlog?.metaTitle}</title>
        <meta name="description" content={filteredBlog?.metaDescription} />
        <link rel="canonical" href={filteredBlog?.canonical} />
        {/* OG Tags */}
        <meta property="og:title" content={filteredBlog?.metaTitle} />
        <meta property="og:site_name" content="Game Vault 777 Slots/" />
        <meta property="og:url" content={filteredBlog?.sharingUrl} />
        <meta property="og:description" content={filteredBlog?.ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={filteredBlog?.ogImage} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="blogPage__container">
        <div className="blogPage__header">
          <h1>{blog?.title}</h1>
          <img src={blog?.titleImage} alt="image"></img>
        </div>
        <div className="blogPage__body">
          <div className="blogPage__body--left">
          <div className="blogPage__description">
            {blog?.description?.map((data, index) => {
              return <BlogSection data={data} key={index} />;
            })}
          </div>
            <div className="Allsections">
              {blog?.sections?.map((section, index) => {
                return (
                  <div className="section" key={index}>
                    <h3 className="section__Title" id={`sectionTitle-${index}`}>
                      {section?.title}
                    </h3>
                    {section?.sectionData?.map((data, index) => {
                      return <BlogSection data={data} key={index} />;
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="blogPage__body--right">
            <div className="right__container">
              <div className="blogPage__contentBox">
                <h3>Content</h3>
                <div className="blogPage__contentListing">
                  {blog?.sections?.map((section, index) => {
                    return (
                      <div className="blogPage__content" key={index}>
                         <button onClick={() => scrollToSection(`sectionTitle-${index}`)}>
                        <span className="dot">•</span>
                          <span>{section?.title}</span>{" "}
                          </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="blogPage__recentPosts">
                <h3>Recent Posts</h3>
                {blogArr?.map((blog, index) => {
                  console.log("::blog");
                  return (
                    <div
                      className="card--recentPost"
                      key={index}
                      onClick={() =>
                        handleNavigation(blog?.id, blog?.blogCard__title)
                      }
                    >
                      <div className="card__body--recentPost">
                        <img src={blog?.blogCard__mage} alt="image" />
                        <span>{blog?.blogCard__title}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="blogPage__shareBox">
                <h3>Share</h3>
                <div>
                  <button onClick={handleFacebookShare}>
                    <img
                      src="/assets/facebook-share-icon.svg"
                      alt="facebook share"
                    ></img>
                  </button>
                  <button onClick={handleWhatsAppShare}>
                    <img
                      src="/assets/whatsapp-share-icon.svg"
                      alt="whatsapp share"
                    ></img>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BlogPage;
