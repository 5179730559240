import { Helmet } from "react-helmet";
import "../../styles/Disclaimer.css";
import { siteUrl } from "../Blogs/data";
const Disclaimer = () => {
  return (
    <>
     <Helmet>
        <title>
          {
            "Game Vault 777 Slots - Disclaimer"
          }
        </title>
        <meta
          name="description"
          content={
            "Our platform does not create or maintain the Game Vault Casino app. We serve as an informational resource, providing details about the app and facilitating its download."
          }
        />
        <link
          rel="canonical"
          href={`${siteUrl}/disclaimer/game-vault-777-slots-disclaimer`}
        />
        {/* OG Tags */}
        <meta
          property="og:title"
          content={
            "Game Vault 777 Slots - Disclaimer"
          }
        />
        <meta property="og:site_name" content="Game Vault 777 Slots/" />
        <meta
          property="og:url"
          content={`${siteUrl}/disclaimer/game-vault-777-slots-disclaimer`}
        />
        <meta
          property="og:description"
          content={
            "Our platform does not create or maintain the Game Vault Casino app. We serve as an informational resource, providing details about the app and facilitating its download."
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={""} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="disclaimer__container">
        <h1>Game Vault Disclaimer</h1>
        <h3>We are not the developers of the Game Vault Casino app:</h3>
        <p>
          Our platform does not create or maintain the Game Vault Casino app. We
          serve as an informational resource, providing details about the app
          and facilitating its download.
        </p>
        <h3>We do not collect personal information:</h3>
        <p>
          We do not gather, store, or process any personal data from users
          visiting our website or using our services.
        </p>
        <h3>We do not handle payments:</h3>
        <p>
          We do not manage or process any payments, whether in currency, online
          transactions, or cryptocurrencies. Our role is limited to providing
          information about the Game Vault Casino app.
        </p>
        <h3>We only provide app information and download access:</h3>
        <p>
          Our primary function is to inform users about the Game Vault Casino
          app's features and to offer a link for its download.
        </p>
        <h3>We are not responsible for any losses or damages:</h3>
        <p>
          While we aim to provide accurate and reliable information, we are not
          liable for any direct or indirect losses or damages resulting from the
          use of our website or the Game Vault Casino app.
        </p>
        <h3>
          We are not liable for any consequences from using casino apps:
        </h3>
        <p>
          Using casino apps, including the Game Vault Casino app, involves
          inherent risks. We disclaim responsibility for any negative effects or
          outcomes that may arise from their use.
        </p>
      </div>
    </>
  );
};
export default Disclaimer;
