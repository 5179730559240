import { useEffect, useState } from "react";
import "../../styles/Blogs.css";
import { blogList } from "./data";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Blogs = () => {
  const siteUrl = "https://gamevault777slots.com/";
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setBlogs(blogList);
  }, []);
  const formatTitleForURL = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };
  const handleNavigation = (id, metaTitle) => {
    const formattedText = formatTitleForURL(metaTitle);
    navigate(`/blogs/${formattedText}`,{state:{id}});
  };
  return (
    <>
      <Helmet>
        <title>
          {
            "Game Vault 777 Slots Blog - Stay Updated with Latest Casino Insights"
          }
        </title>
        <meta
          name="description"
          content={
            "Discover the latest gaming tips, slot strategies, and updates with our Game Vault 777 Slots blog. One destination for expert insights and exciting gaming news!"
          }
        />
        <link
          rel="canonical"
          href={`${siteUrl}/blogs/game-vault-777-slots-blog-stay-updated-with-latest-casino-insights`}
        />
        {/* OG Tags */}
        <meta
          property="og:title"
          content={
            "Game Vault 777 Slots Blog - Stay Updated with Latest Casino Insights"
          }
        />
        <meta property="og:site_name" content="Game Vault 777 Slots/" />
        <meta
          property="og:url"
          content={`${siteUrl}/blogs/game-vault-777-slots-blog-stay-updated-with-latest-casino-insights`}
        />
        <meta
          property="og:description"
          content={
            "Discover the latest gaming tips, slot strategies, and updates with our Game Vault 777 Slots blog. One destination for expert insights and exciting gaming news!"
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={""} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="blogs__container">
        <div className="blogs__header">Blogs</div>
        <div className="blogs__body">
          <div className="blogs__body--left">
            {blogs?.map((blog, index) => {
              return (
                <div className="card--blog" key={index}   onClick={() =>
                  handleNavigation(blog?.id, blog?.blogCard__title)
                }>
                  <div className="card__header--blog">
                    <img src={blog?.blogCard__mage} alt="image" />
                  </div>
                  <div className="card__body--blog">
                    <span>{blog?.blogCard__title}</span>
                    <button
                      className="button--blogCard"
                      onClick={() =>
                        handleNavigation(blog?.id, blog?.blogCard__title)
                      }
                    >
                      Read More
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="blogs__body--right">
            <div className="blogsPage__body__title">Recent Posts</div>
            {blogs?.map((blog, index) => {
              return (
                <div
                  className="card--recentPost"
                  key={index}
                  onClick={() =>
                    handleNavigation(blog?.id, blog?.blogCard__title)
                  }
                >
                  <div className="card__body--recentPost">
                    <img src={blog?.blogCard__mage} alt="image" />
                    <span>{blog?.blogCard__title}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Blogs;
