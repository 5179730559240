import React from "react";
import "../styles/Footer.css";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    scrollToTop();
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="footer-bg">
      <Container>
        <div className="footer-img">
          <img alt="footer-image" src="/assets/footer-image.svg" />
        </div>
        <div className="footer__quickLinks">
          <button
            onClick={() => {
              handleNavigation("/privacy-policy");
            }}
          >
            Privacy Policy
          </button>
          <button
            onClick={() => {
              handleNavigation("/terms-conditions");
            }}
          >
            Terms and Conditions
          </button>
          <button
            onClick={() => {
              handleNavigation("/disclaimer");
            }}
          >
            Disclaimer
          </button>
        </div>
        <div className="footer-txt">
          Copyright © 2024 Game Vault Online Game. All Rights Reserved.
        </div>
      </Container>
    </div>
  );
};

export default Footer;
