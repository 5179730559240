import React from "react";
import "../../styles/PrivacyPolicy.css";
import { Helmet } from "react-helmet";
import { siteUrl } from "../Blogs/data";
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>
          {
            "Game Vault 777 Slots - Privacy Policy"
          }
        </title>
        <meta
          name="description"
          content={
            "This Privacy Policy outlines how we collect, use, and protect your personal information while explaining the rights you have regarding your data."
          }
        />
        <link
          rel="canonical"
          href={`${siteUrl}/privacy-policy/game-vault-777-slots-privacy-policy`}
        />
        {/* OG Tags */}
        <meta
          property="og:title"
          content={
            "Game Vault 777 Slots - Privacy Policy"
          }
        />
        <meta property="og:site_name" content="Game Vault 777 Slots/" />
        <meta
          property="og:url"
          content={`${siteUrl}/privacy-policy/game-vault-777-slots-privacy-policy`}
        />
        <meta
          property="og:description"
          content={
            "This Privacy Policy outlines how we collect, use, and protect your personal information while explaining the rights you have regarding your data."
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={""} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="privacyPolicy__container">
      <h1>Game Vault Privacy Policy</h1>
      <p>
        This privacy policy governs the customer’s or user’s (“you,” “your”) use
        of the Game Vault software (“Software”) and our website
        <span>
          <a href="https://gamevault777slots.com/" target="_blank"> (https://gamevault777slots.com/)</a>
        </span>
        . At Game Vault (“the company,” “we,” “us,” or “our”), your privacy
        matters to us. We are committed to protecting your personal information
        and ensuring compliance with all applicable data protection laws and
        regulations.
      </p>
      <p>
        This Privacy Policy outlines how we collect, use, and protect your
        personal information while explaining the rights you have regarding your
        data. By using our Software and website, you agree to the practices
        described in this policy.
      </p>
      <h2>User-Provided Information and How We Use Them</h2>
      <p>
        Signing up for our Software and website services is entirely optional.
        However, please note that certain features may not be accessible unless
        you complete the registration process. When you do register with us, you
        typically provide:
      </p>
      <div className="textWithBullets__container">
        <div className="textWithBullets--keyValue">
          <span>Personal Details:</span>
          <span>
            Such as your name, phone number, email address, username, password,
            and other relevant registration data.
          </span>
        </div>
        <div className="textWithBullets--keyValue">
          <span>Support Interaction:</span>
          <span>
            Information shared when you reach out to us for assistance, enabling
            us to address your queries effectively.
          </span>
        </div>
        <div className="textWithBullets--keyValue">
          <span>User-Entered Data:</span>
          <span>
            Any details you input into our system during your use of the
            Software, which may include contact information and other related
            data.
          </span>
        </div>
      </div>
      <h2>Purpose</h2>
      <span>Here’s how we use the information you provide:</span>
      <div className="textWithBullets__container">
        <div className="textWithBullets">
          To create your account and deliver personalized experiences.
        </div>
        <div className="textWithBullets">
          To verify your identity for security purposes.
        </div>
        <div className="textWithBullets">
          To improve our website and services through analysis and feedback.{" "}
        </div>
        <div className="textWithBullets">
          To help you quickly find services and information relevant to you.
        </div>
        <div className="textWithBullets">
          To streamline your navigation by ensuring you don’t have to re-enter
          details repeatedly.
        </div>
        <div className="textWithBullets">
          To send you updates and notifications about our services.
        </div>
      </div>
      <p>
        By using our services, you agree to be contacted by us via phone, email,
        or written communication. You acknowledge that such communication aligns
        with applicable data protection laws and does not breach your rights.
      </p>
      <span>
        Your privacy matters to us, and we ensure that your data is handled
        responsibly.
      </span>
      <h2>Automatically Collected Information</h2>
      <span>
        In addition, the software can collect information automatically,
        including, but not limited to,
      </span>
      <div className="textWithBullets__container">
        <div className="textWithBullets">
          Your device type, unique device ID, and IP address.
        </div>
        <div className="textWithBullets">
          Your device’s operating system and the internet browsers you use.
        </div>
        <div className="textWithBullets">
          Insights about how you interact with the software.
        </div>
      </div>
      <p>
        We may also use GPS or similar technology to identify your city and
        display relevant location-based advertisements. Rest assured, your
        precise location will not be shared with other users or partners.
      </p>
      <p>
        If you prefer not to share your location, you can disable location
        services for the software in your device settings. Your privacy remains
        our priority.
      </p>
      <h2>Legitimate Interest</h2>
      <span>
        We may share your information with third parties only, as outlined in
        this privacy statement.
      </span>
      <span>
        We may disclose user-provided and automatically collected information:
      </span>
      <div className="textWithBullets__container">
        <div className="textWithBullets">
          To comply with legal obligations, such as subpoenas or similar
          processes.
        </div>
        <div className="textWithBullets">
          When necessary, to protect our rights, ensure your safety or the
          safety of others, investigate fraud, or respond to government
          requests.
        </div>
        <div className="textWithBullets">
          With trusted service providers who act on our behalf, adhere to this
          privacy policy, and do not use the information for independent
          purposes.
        </div>
        <div className="textWithBullets">
          In the event of a merger, acquisition, or sale of assets, you will be
          notified via email or a prominent website notice about ownership
          changes and your information rights.
        </div>
      </div>
      <span>
        Your trust is important to us, and we ensure transparency in how your
        data is handled.
      </span>
      <h2>What are my opt-out rights?</h2>
      <span>
        You can stop all collection of information by the software easily by
        uninstalling or disabling the software, where applicable.
      </span>
      <h2>Data Retention Policy, Managing Your Information</h2>
      <p>
        We will retain user-provided data for as long as you use the software
        and for a reasonable period afterward. Information that is automatically
        gathered will be retained for a maximum of 24 months, after which it may
        be aggregated and archived. Please be aware that certain user-provided
        information might be necessary for the software to operate properly.
      </p>
      <h2>Security</h2>
      <p>
        We are dedicated to maintaining the privacy of your data. We use
        procedural, technological, and physical safeguards to protect it. Only
        authorized workers and contractors that need it to run, create, or
        improve our software are allowed access.
      </p>
      <p>
        While we strive to provide robust security for the data we process and
        maintain, please be aware that no system can guarantee complete
        protection against all potential security breaches.
      </p>
      <h2>Media</h2>
      <p>
        Avoid including embedded location data (EXIF GPS) when uploading images
        to our website. Visitors to the site can download and extract location
        data from any image posted.
      </p>
      <h2>Embedded content from other websites</h2>
      <p>
        Videos, pictures, and articles may all be embedded on our website. This
        embedded content functions as if you have visited the third-party
        website directly. These external websites could employ cookies, gather
        your information, and utilize other third-party tracking methods.
        Additionally, if you have an account on that website, they could track
        your behavior and monitor how you engage with the embedded information.
      </p>
      <h2>Data Processing</h2>
      <p>
        Our cloud providers store and process data in the countries where they
        operate. However, we are committed to taking all necessary measures to
        safeguard your privacy and protect your information.
      </p>
      <h2>Changes</h2>
      <p>
        We may update this Privacy Policy periodically for various reasons. Any
        changes will be communicated by posting the revised policy on this page
        and notifying you through a pop-up on the website, email, or in-software
        message. We encourage you to review this Privacy Policy regularly, as
        continued use of our services signifies your acceptance of any updates.
      </p>
      <h2>Your Rights</h2>
      <p>
        Under data protection laws, you have several rights regarding our
        handling of your personal data, subject to certain statutory exceptions.
        These rights include:
      </p>
      <div className="textWithNumbers">
        <span>
          1. The right to access your personal data and learn how it is
          processed.
        </span>
        <span>
          2. The right to correct or complete inaccurate or incomplete
          information.
        </span>
        <span>
          3. The right to delete personal data processed unlawfully provided the
          basis was consent.
        </span>
        <span>
          4.The right to restrict the processing of your personal data.
        </span>
        <span>
          5. The right to data portability allows you to transfer personal data
          in an electronic format.
        </span>
      </div>
      <p>
        You also have the right to withdraw consent previously given for the
        processing of your personal data at any time. However, this does not
        affect any data we are required to retain for administrative, legal, or
        security reasons.
      </p>
      <h2>Data Breach</h2>
      <p>
        We are committed to safeguarding your personal information and ensuring
        that your preferences regarding its use are respected. We implement
        robust security measures to protect your data from loss, misuse,
        unauthorized access, alteration, or destruction.
      </p>
      <p>
        In the unlikely event of a data breach due to unforeseen circumstances,
        we will notify you within 72 hours of identifying the breach.
      </p>
      <h2>Contact forms - Cookies</h2>
      <p>
        When you leave a comment on our site, you have the option to save your
        name, email, and website in cookies for your convenience. This prevents
        you from having to re-enter your details for future comments, with
        cookies lasting for one year.
      </p>
      <p>
        If you log in to your account, we set a temporary cookie to verify your
        browser's ability to accept cookies. This cookie contains no personal
        data and is discarded when you close your browser. When logged in,
        additional cookies are set to remember your login details and screen
        preferences. Login cookies last for two days, while screen option
        cookies last for one year.
      </p>
      <p>
        Choosing the “Remember Me” option will keep you logged in for about two
        weeks. After you log out, we will remove the login cookies.
      </p>
      <p>
        A cookie is added to your browser whenever you publish or amend an
        article. This cookie just identifies the post ID of the article you
        altered; it doesn't hold any personal information. It runs out in a day.
      </p>
      <p>
        Comments and their metadata are retained indefinitely to enable
        automatic recognition and approval of follow-up comments, bypassing the
        moderation queue.
      </p>
      <p>
        Additionally, when visitors comment, we collect the data provided in the
        comment form and the their IP address and browser user agent string to
        assist with spam detection.
      </p>
      <h2>Your Consent</h2>
      <p>
        By accessing and using our software and website, you agree to the
        processing of your information as described in this Privacy Policy and
        any future updates. "Processing" encompasses all activities related to
        your data, including but not limited to collecting, storing, using,
        combining, disclosing, and deleting information. These activities are
        carried out on our servers and may involve the use of cookies on your
        device.
      </p>
      <h2>Agreement and Litigation</h2>
      <p>
        By using our software and support services, you consent to the terms
        outlined in this Privacy Policy and any other agreements we may have
        with you. If you do not agree to these terms, please refrain from using
        our software or website. Any disputes related to privacy or the terms of
        this Privacy Policy or any other agreement will be governed by U.S. law.
        Additionally, you agree to resolve such disputes through arbitration
        (via the contact form) and to adhere to any limitations on damages
        specified in our agreements.
      </p>
      <h2>Users from Outside the USA</h2>
      <p>
        If you are accessing our services from outside the USA, please be aware
        that your information may be processed, stored, and transferred to AITC,
        NP, where our central database is located. While data protection laws in
        the USA may not be as comprehensive as those in your country, we are
        committed to taking all necessary measures to safeguard your privacy.
      </p>
      <h2>Contact Details, Complaints, and Questions</h2>
      <p>
        If you have any questions about our Privacy Policy, please reach out to
        us via our contact form or email. We are here to assist you.
      </p>
    </div>
    </>
  
  );
};

export default PrivacyPolicy;
